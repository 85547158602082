import React from 'react'

import { Body2, Headline6 } from 'components/Texts'

import { AuthorWrapper, AuthorDetails } from './styledComponents'
import { AUTHOR_IMAGE_STYLES } from './consts'

const PreviewAuthor = ({ name, shortBio, imageSrc }) => (
  <AuthorWrapper>
    <img src={imageSrc} alt={name} style={AUTHOR_IMAGE_STYLES} />

    <AuthorDetails>
      <Headline6 bold themeColor="highBlack">
        {name}
      </Headline6>
      <Body2>{shortBio}</Body2>
    </AuthorDetails>
  </AuthorWrapper>
)

export default PreviewAuthor
