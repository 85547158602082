import { defineMessages } from 'react-intl'
import { shouldUpdate } from 'recompose'
import _mapValues from 'lodash/mapValues'
import _camelCase from 'lodash/camelCase'
import _isPlainObject from 'lodash/isPlainObject'
import _isArray from 'lodash/isArray'
import _toString from 'lodash/toString'
import _mapKeys from 'lodash/mapKeys'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _pick from 'lodash/pick'
import _isEqual from 'lodash/isEqual'
import _snakeCase from 'lodash/snakeCase'
import _upperFirst from 'lodash/upperFirst'
import _filter from 'lodash/filter'

export const mapKeysDeep = cb => obj => {
  if (!_isPlainObject(obj)) return obj

  return _mapValues(_mapKeys(obj, cb), val => {
    if (_isPlainObject(val)) return mapKeysDeep(cb)(val)
    if (_isArray(val)) return _map(val, v => mapKeysDeep(cb)(v))
    return val
  })
}

export const cameliseDeep = value => {
  const cameliseFn = (_, key) => {
    const isFirstKeyLetterUppercase = key.match(new RegExp(/^[A-Z]/)) !== null

    return isFirstKeyLetterUppercase
      ? _upperFirst(_camelCase(key))
      : _camelCase(key)
  }
  return _isArray(value)
    ? value.map(mapKeysDeep(cameliseFn))
    : mapKeysDeep(cameliseFn)(value)
}

export const snakeCaseDeep = value => {
  const snakeCaseFn = (_, key) => _snakeCase(key)
  return _isArray(value)
    ? value.map(mapKeysDeep(snakeCaseFn))
    : mapKeysDeep(snakeCaseFn)(value)
}

export const getResponseError = response => {
  const bodyErrors = _get(response, 'body.errors')

  if (bodyErrors) {
    return bodyErrors.map(({ message } = {}) => message)
  }

  return response.statusText || 'Connection error'
}

export const shouldUpdateHelper = propsToPick =>
  shouldUpdate((props, nextProps) => {
    const propsToCompare = _pick(props, propsToPick)
    const nextPropsToCompare = _pick(nextProps, propsToPick)
    return !_isEqual(propsToCompare, nextPropsToCompare)
  })

/* eslint-disable */
export const makeTranslation = scope => key =>
  _isPlainObject(key)
    ? {
        id: key.id,
        defaultMessage: `missing ${key.id}`,
      }
    : {
        id: `${scope}.${key}`,
        defaultMessage: `missing ${scope}.${key}`,
      }
/* eslint-enable */

export const makeMessages = (messages, useTranslation) =>
  defineMessages(_mapValues(messages, useTranslation))

export const getNavigatorLanguage = () =>
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.userLanguage ||
      navigator.language ||
      navigator.browserLanguage ||
      navigator.systemLanguage ||
      'de'

export const toBase64 = ({ file, callback }) =>
  new Promise((resolve, reject) => {
    if (typeof file === 'string') {
      if (callback) {
        callback(file)
      }
      resolve(file)
      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      if (callback) {
        callback(reader.result)
      }
      resolve(reader.result)
    }
    reader.onerror = error => reject(error)
  })

export function bytesToSize(bytes) {
  if (!bytes) return ''
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Byte'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
  return `${Math.round(bytes / 1024 ** i, 2)} ${sizes[i]}`
}

export const loadScript = src =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.addEventListener('load', () => resolve())
    script.addEventListener('error', error => reject(error))
    document.body.appendChild(script)
  })

export const getImageUrl = img =>
  typeof img === 'string' ? img : URL.createObjectURL(img.file)

export const getNonEmptyStringsArray = array =>
  _filter(array, string => string && string.trim())

export const compareStrings = (val1, val2) =>
  _toString(val1) === _toString(val2)

export const compareStringsFp = val1 => val2 => compareStrings(val1, val2)

export const addMissingHttpUrlPart = url => {
  if (!url || typeof url !== 'string') {
    return ''
  }

  const HTTP = 'http'
  const HTTPS = 'https'
  const LEADING_SLASHES = '//'

  if (url.substr(0, 4) === HTTP) {
    return url
  }

  const hasLeadingSlashes = url.substr(0, 2) === LEADING_SLASHES

  return `${HTTPS}:${hasLeadingSlashes ? '' : LEADING_SLASHES}${url}`
}

export { isFirstPage } from './isFirstPage'
