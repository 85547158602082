import { Flex, Box } from '@rebass/grid'
import styled from 'styled-components'

import { theme } from 'styles/themes'

export const MoreArticlesItem = styled(Flex)`
  flex-direction: column;
  width: 100%;
`

export const ImageWrapper = styled(Flex)`
  max-height: 205px;

  @media (max-width: ${theme.Breakpoints.tablet}) {
    max-height: 400px;
  }
`

export const MoreArticlesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 48px;

  @media (max-width: ${theme.Breakpoints.tablet}) {
    grid-template-columns: 1fr;
    align-self: center;
  }

  margin: 48px 0 48px 0;
  ${({ isSmall }) =>
    isSmall &&
    `
      grid-template-columns: 1fr;
      margin: 0;
  `}
`

export const MoreArticlesContainer = styled(Flex)`
  flex-direction: column;

  ${({ isSmall }) =>
    isSmall &&
    `
      @media (max-width: ${theme.Breakpoints.tablet}) {
        display: none;
      }
  `}
`

export const Spacer = styled(Box)`
  width: 100%;
  height: 1px;
  background-color: #dedede;
`
