import _ from 'lodash'
import moment from 'moment'

import previewClient from 'utils/previewClient'
import markdownToHTML from 'utils/markdownToHTML'

/* eslint-disable consistent-return */
const preparePreviewData = async () => {
  const url = new URL(window.location.href)
  const entryUrl = url.searchParams.get('entryUrl')

  try {
    const blogPosts = await previewClient.getEntries({
      content_type: 'blogPost',
      include: 2,
      'sys.id': entryUrl,
    })

    const { fields } = blogPosts.items[0]
    const author = _.get(fields, 'author.fields')
    const authorObject = {
      name: _.get(author, 'name'),
      shortBio: _.get(author, 'shortBio'),
      imageSrc: _.get(author, 'image.fields.file.url'),
    }

    return {
      contentfulBlogPost: {
        title: _.get(fields, 'title', 'SECmarket'),
        author: authorObject,
        slug: _.get(fields, 'slug'),
        heroImage: _.get(fields, 'heroImage.fields.file.url'),
        publishDate: moment(_.get(fields, 'publishDate', Date.now())).format(
          'DD.MM.YY hh:mm',
        ),
        description: _.get(fields, 'description', ''),
        moreArticles: _.get(fields, 'moreArticles'),
        body: markdownToHTML(_.get(fields, 'body')),
        footer: _.get(fields, 'footer'),
      },
    }
  } catch (err) {
    console.error(err)
  }
}

export default preparePreviewData
