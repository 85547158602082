import React from 'react'
import Img from 'gatsby-image'
import _ from 'lodash'
import { Flex } from '@rebass/grid'

import { Body2, Headline6 } from 'components/Texts'

import { AuthorWrapper, AuthorDetails } from './styledComponents'
import { AUTHOR_IMAGE_STYLES } from './consts'

const Author = ({ author, placeholderImage }) => {
  const { name, shortBio, heroImage } = author || {}

  return (
    <AuthorWrapper>
      <Flex style={{ flex: 1 }}>
        <Img
          alt={`${name} - profile image`}
          fluid={heroImage?.fluid || placeholderImage}
          style={AUTHOR_IMAGE_STYLES}
        />
      </Flex>
      <AuthorDetails>
        <Headline6 bold themeColor="highBlack">
          {name}
        </Headline6>
        <Body2>{_.get(shortBio, 'shortBio')}</Body2>
      </AuthorDetails>
    </AuthorWrapper>
  )
}

export default Author
