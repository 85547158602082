import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { theme } from 'styles/themes'

export const AuthorWrapper = styled(Flex)`
  border-radius: 3px;
  border: solid 1px #f0f0f0;
  flex: 1 0 auto;
  padding: 32px;
  max-width: 635px;
  margin: 14px 0 72px 0;

  @media (max-width: ${theme.Breakpoints.mobile}) {
    padding: 16px;
  }
`

export const AuthorDetails = styled(Flex)`
  flex-direction: column;
  padding-left: 32px;

  @media (max-width: ${theme.Breakpoints.mobile}) {
    padding-left: 16px;
  }
`
