import { createClient } from 'contentful'

import {
  CONTENTFUL_SPACE_ID,
  CONTENTFUL_ENVIRONMENT,
  CONTENTFUL_PREVIEW_HOST,
  CONTENTFUL_PREVIEW_TOKEN,
} from 'consts'

const previewClient = createClient({
  space: CONTENTFUL_SPACE_ID,
  environment: CONTENTFUL_ENVIRONMENT,
  accessToken: CONTENTFUL_PREVIEW_TOKEN,
  host: CONTENTFUL_PREVIEW_HOST,
})

export default previewClient
