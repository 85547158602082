import styled, { css } from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import { theme } from 'styles/themes'

export const TextWrapper = styled(Flex)`
  justify-content: ${({ isTitleCentered }) =>
    isTitleCentered ? 'center' : 'initial'};

  ${({ withUnderline }) =>
    withUnderline
      ? `
        padding-bottom: 12px;
        border-bottom: 1px solid ${theme.Colors.primary400};
        margin-bottom: 24px;
      `
      : `
        margin-bottom: 16px;
      `}
`

export const SocialLinkStyles = styled(Box)`
  ${({ vertical }) =>
    vertical
      ? css`
          :not(:first-child) {
            margin-top: 16px;
          }
        `
      : css`
          :not(:first-child) {
            margin-left: 16px;
          }
        `}
`
