import React from 'react'
import { Flex, Box } from '@rebass/grid'
import Helmet from 'react-helmet'

import { BLOG_URL, XING_URL, TWITTER_URL, FACEBOOK_URL } from 'consts'
import { Facebook, Twitter, Xing } from 'components/Icons'
import { Headline6 } from 'components/Texts'
import Translation from 'utils/Translation'

import { TextWrapper, SocialLinkStyles } from './styledComponents'

const SocialLink = ({ url, vertical, Icon, shareURL }) => (
  <SocialLinkStyles
    as="a"
    target="_blank"
    href={`${url}${shareURL}`}
    {...{ vertical }}
  >
    <Icon />
  </SocialLinkStyles>
)

const SOCIAL_LINKS = [
  {
    Icon: Facebook,
    url: FACEBOOK_URL,
  },
  {
    Icon: Twitter,
    url: TWITTER_URL,
  },
  {
    Icon: Xing,
    url: XING_URL,
  },
]

// TODO: Can't export it outside this component, some unknown error occures
const OGTags = ({ title, postDescription, imageURL, shareURL }) => (
  <Helmet>
    <meta property="og:type" content="website" />
    <meta property="og:url" content={shareURL} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={postDescription} />
    <meta property="og:image" content={imageURL} />
  </Helmet>
)

const SocialMedia = ({
  vertical,
  withTitle = true,
  withUnderline,
  isTitleCentered,
  title,
  postDescription,
  imageURL,
  location: { pathname },
  location,
  ...rest
}) => {
  const shareURL = `${BLOG_URL}${pathname}`

  return (
    <>
      <OGTags {...{ title, postDescription, shareURL, imageURL }} />

      <Box {...(isTitleCentered && { style: { maxWidth: '240px' } })} {...rest}>
        {withTitle && !vertical && (
          <TextWrapper {...{ isTitleCentered, withUnderline }}>
            <Headline6 bold>
              <Translation id="share" />
            </Headline6>
          </TextWrapper>
        )}

        <Flex flexDirection={vertical ? 'column' : 'row'}>
          {SOCIAL_LINKS.map(({ Icon, url }, index) => (
            <SocialLink key={index} {...{ Icon, url, vertical, shareURL }} />
          ))}
        </Flex>
      </Box>
    </>
  )
}

export default SocialMedia
