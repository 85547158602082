import React from 'react'
import { DiscussionEmbed } from 'disqus-react'

import { useCookieWatcher } from 'components/Cookies/useCookieWatcher'

const DiscussionEmbedWrapper = ({ isPreviewMode, disqusConfig }) => {
  const { exist: isCookieAccepted } = useCookieWatcher('OptanonAlertBoxClosed')

  return (
    <>
      {isCookieAccepted && !isPreviewMode && (
        <div style={{ margin: '30px 0 60px 0' }}>
          <DiscussionEmbed {...disqusConfig} />
        </div>
      )}
    </>
  )
}

export default DiscussionEmbedWrapper
