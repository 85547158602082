import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import _ from 'lodash'
import Img from 'gatsby-image'
import { Flex, Box } from '@rebass/grid'

import { Body1, Headline3, Headline5 } from 'components/Texts'
import useTranslation from 'utils/Translation/useTranslation'
import Layout, { ColumnGrid, Inner } from 'components/Layout'
import heroStyles from 'components/hero.module.css'
import MoreArticles from 'components/MoreArticles'
import SocialMedia from 'components/SocialMedia'
import DiscussionEmbed from 'components/DiscussionEmbed'
import Author, { PreviewAuthor } from 'components/Author'
import { usePreview, usePathname } from 'hooks'
import { blogPostPreview } from 'previews'
import { addMissingHttpUrlPart } from 'utils'

import {
  ArticleContainer,
  ArticleFooter,
  HTMLWrapper,
  Sidebar,
} from 'components/styledComponents'

import { BLOG_URL, isPreview, COOKIE_PRO_ID } from 'consts'

const BlogPostTemplate = ({ data, location }) => {
  const { isPreviewMode, previewData } = usePreview(blogPostPreview)
  //  this needs to be done due to Gatsby SSR and using document object in Cookies component
  const [isBrowser, setIsBrowser] = useState(false)

  const dataSource = isPreviewMode ? previewData : data
  const post = _.get(dataSource, 'contentfulBlogPost', {})
  const placeholderImage = _.get(data, 'file.childImageSharp.fluid')
  const { title, author, slug, heroImage, publishDate, body, footer } = post

  const moreArticles = _.get(post, 'moreArticles') || []
  const postDescription = _.get(post, 'description.description', '')
  const siteTitle = _.get(
    dataSource,
    'contentfulWebPageMetaTags.siteTitle',
    isPreviewMode && 'Blog Post Preview',
  )

  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: {
      identifier: slug,
      title: siteTitle,
      url: `${BLOG_URL}/blog/${slug}`,
    },
  }

  const socialMediaConfig = {
    postDescription: postDescription || useTranslation('blogDescription'),
    imageURL: addMissingHttpUrlPart(_.get(heroImage, 'file.url')),
    title,
    location,
  }

  const paths = usePathname()
  if (isPreviewMode !== true && isPreview(paths)) {
    return null
  }

  useEffect(() => setIsBrowser(true))

  return (
    <Layout {...{ location }}>
      <Inner>
        <Helmet>
          <title>{`${title} | ${siteTitle}`}</title>
          <meta name="format-detection" content="telephone=no" />
          <meta
            name="description"
            content={postDescription || useTranslation('blogDescription')}
          />
          <script
            src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
            type="text/javascript"
            charSet="UTF-8"
            data-domain-script={COOKIE_PRO_ID}
          ></script>
          <script type="text/javascript">function OptanonWrapper() {}</script>
        </Helmet>

        <Box mb="56px" className={heroStyles.hero}>
          {isPreviewMode ? (
            <img
              style={{ height: '445px', width: 'auto' }}
              src={heroImage}
              alt="hero"
            />
          ) : (
            <Img
              alt={`article image - ${title}`}
              className={heroStyles.heroImage}
              fluid={heroImage?.fluid || placeholderImage}
            />
          )}
        </Box>

        <ColumnGrid>
          <article>
            <Headline3 mb="32px" extraBold>
              {title}
            </Headline3>

            <ArticleContainer>
              <Body1 mb="40px" width="100%">
                {`${publishDate} • ${_.get(author, 'name', '')}`}
              </Body1>

              <Headline5 mt="8px" bold mb="72px">
                {postDescription}
              </Headline5>

              <Box>
                <HTMLWrapper
                  dangerouslySetInnerHTML={{
                    __html: isPreviewMode
                      ? body
                      : _.get(body, 'childMarkdownRemark.html'),
                  }}
                />
              </Box>
            </ArticleContainer>

            <ArticleFooter>{footer}</ArticleFooter>

            {isPreviewMode ? (
              <PreviewAuthor {...author} />
            ) : (
              <Author {...{ author, placeholderImage }} />
            )}
          </article>

          <Flex flexDirection="column">
            <SocialMedia
              {...socialMediaConfig}
              vertical
              mb="200px"
              mr="10px"
              style={{ alignSelf: 'flex-end' }}
            />
            <Sidebar>
              <MoreArticles {...{ moreArticles }} isSmall />
              <SocialMedia {...socialMediaConfig} withUnderline mt="70px" />
            </Sidebar>
          </Flex>
        </ColumnGrid>

        {isBrowser && (
          <DiscussionEmbed
            disqusConfig={disqusConfig}
            isPreviewMode={isPreviewMode}
          />
        )}

        {!isPreviewMode && (
          <MoreArticles {...{ moreArticles, placeholderImage }} />
        )}
      </Inner>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String) {
    site {
      siteMetadata {
        title
      }
    }

    contentfulWebPageMetaTags(page: { eq: "blog" }) {
      siteTitle
    }

    file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    contentfulBlogPost(slug: { eq: $slug }) {
      heroImage {
        file {
          url
        }
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      title
      slug
      author {
        name
        shortBio {
          shortBio
        }
        title
        heroImage: image {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
      moreArticles {
        title
        tags
        slug
        heroImage {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
      publishDate(formatString: "DD.MM.YY HH:MM")
      description {
        description
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      footer
    }
  }
`
