import remark from 'remark'
import recommended from 'remark-preset-lint-recommended'
import html from 'remark-html'

const markdownToHTML = markdown =>
  remark()
    .use(recommended)
    .use(html)
    .processSync(markdown)
    .toString()

export default markdownToHTML
