import React from 'react'
import Img from 'gatsby-image'
import { Flex } from '@rebass/grid'
import _ from 'lodash'

import { Headline5, Body2 } from 'components/Texts'
import { NoStylesLink } from 'components/Links'
import Headline6 from 'components/Texts/Headline6'
import Translation from 'utils/Translation'

import {
  Spacer,
  ImageWrapper,
  MoreArticlesWrapper,
  MoreArticlesItem,
  MoreArticlesContainer,
} from './styledComponents'

const MoreArticles = ({ moreArticles = [], isSmall, placeholderImage }) =>
  !!moreArticles.length && (
    <MoreArticlesContainer {...{ isSmall }}>
      <Headline5 bold themeColor="highBlack" mb="15px">
        <Translation id="moreArticles" />
      </Headline5>

      <Spacer />

      <MoreArticlesWrapper {...{ isSmall }}>
        {moreArticles.map(({ title, heroImage, tags, slug } = {}) => (
          <MoreArticlesItem key={title}>
            <NoStylesLink to={`/blog/${slug}`}>
              {!isSmall && (
                <ImageWrapper>
                  <Img
                    alt={`article image - ${slug}`}
                    fluid={_.get(heroImage, 'fluid', placeholderImage)}
                    style={{ flex: '1' }}
                  />
                </ImageWrapper>
              )}

              <Flex flexDirection="column">
                {tags ? (
                  <Body2 gray pt="20px">
                    {tags.join(' & ')}
                  </Body2>
                ) : (
                  <Flex height="40px" />
                )}

                <Headline6 bold themeColor="highBlack" margin={0}>
                  {title}
                </Headline6>
              </Flex>
            </NoStylesLink>
          </MoreArticlesItem>
        ))}
      </MoreArticlesWrapper>
    </MoreArticlesContainer>
  )

export default MoreArticles
